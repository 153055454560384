import React from 'react'
import styles from './story.module.css';
import { extensions_image, extensions_video } from '../Stories';

const Story = (props) => {
    if (extensions_video.includes(props.story.extension)) {
        return (
            <div>
                <video className={styles.story__vid} src={`https://api.storieswall.com/files/wall-1/${props.story.src}`} loop autoPlay muted playsInline></video>
            </div>
        )
    }
    else if (extensions_image.includes(props.story.extension)) {
        return (
            <div>
                <img className={styles.story__img} src={`https://api.storieswall.com/files/wall-1/${props.story.src}`} alt={props.story.alt}></img>
            </div>
        )
    }
    else
        return null
}

export default Story;
