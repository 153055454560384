import React, { useRef, useState, useEffect } from 'react'
import '../App.css';
import axios from 'axios'
import Carousel from 'react-tiny-slider'
import Story from './Story/Story'
import _ from 'lodash'
import { baseUrl } from '../App';


export const extensions_video = [
    '.mp4',
    '.webm',
]

export const extensions_image = [
    '.jpg',
    '.jpeg',
    '.png',
    '.webp',
    '.heic',
]

const extensions = extensions_video.concat(extensions_image)


const Stories = () => {
    console.log('Stories()')
    const [error, setError] = useState(null)
    const [stories, setStories] = useState([])

    const carousel = useRef(null)
    const seconds = 15

    useEffect(() => {
        console.log('useEffect()')
        const getStories = () => {
            console.log('getStories()')
            return axios
                .get(baseUrl)
                .then(response => {
                    console.log('Fulfilled call to API')
                    const stories_latest = response.data.stories
                    console.log(`${stories.length} vs ${stories_latest.length}`)
                    if (!_.isEqual(stories, stories_latest)) {
                        console.log('stories UNEQUAL to stories_latest')
                        setStories(stories_latest)
                    }
                })
                .catch(error => {
                    setError(error)
                })
        }
        getStories()

        const interval = setInterval(() => {
            console.log(`Runs every ${seconds} seconds`)
            getStories()
        }, seconds * 1000)

        return () => clearInterval(interval)
    }, [stories])

    if (error) {
        return (
            <div className="text-white"><h1>Error: {error.message}</h1></div>
        )
    }
    else {
        if (stories.length === 0) {
            return (
                <></>
            )
        }

        return (
            <Carousel
                items={3}
                swipeAngle={false}
                speed={1000}
                mouseDrag={false}
                ref={carousel}
                controls={false}
                nav={false}
                gutter={35}
                autoplay={true}
                autoplayTimeout={7000}
                autoplayButtonOutput={false}
            >
                {stories.map(story => {
                    return <Story key={story.id} story={story} />
                })}

                {stories.filter(story => extensions.includes(story.extension)).map(filteredStory => {
                    return <Story key={filteredStory.id} story={filteredStory} />
                })}
            </Carousel>
        )
    }
}

export default Stories;
